<template>
  <div class="layout">
    <HeaderControllerSkeleton />
    <FeedControllerSkeleton />
    <main class="layout__main">
      <slot></slot>
    </main>
    <FooterControllerSkeleton />
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped src="assets/layouts/default.scss" />
